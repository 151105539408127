<template>
  <el-card
    style="margin-bottom: 20px"
  >
    <div style="display: flex; flex-wrap: nowrap">
      <div
        class="scales-settings-visibility"
        @click="templateSettingsVisible=!templateSettingsVisible"
      >
        <i v-if="!templateSettingsVisible" class="fas fa-angle-down"></i>
        <i v-else class="fas fa-angle-up"></i>
      </div>
      <div class="scales-settings-name" style="flex: 1 1 auto; margin-right: 20px;">
        <click-to-edit
          v-model="template.name"
          :class="{'not-editable': !$canAndRulesPass(user, 'setting.scales_edit')}"
          :disabled="!$canAndRulesPass(user, 'setting.scales_edit')"
          @input="$emit('update-template', template)"
        ></click-to-edit>
      </div>
      <div style="margin-right: 10px">{{ $companyConfiguration.scales.getScaleShowName('progressive') }}</div>
      <div style="">ID: {{ template.id }}</div>

      <div
        style="margin-left: 30px"
        v-if="$canAndRulesPass(user, 'setting.scales_edit')"
      >
        <el-popconfirm
          style="margin-right:10px;"
          confirm-button-text='Да'
          cancel-button-text='нет'
          icon="el-icon-question"
          title="Вы уверены, что хотите удалить шкалу?"
          @confirm="$emit('delete-template', template)"
        >
          <el-button
            slot="reference"
            class="table-action-button"
            size="mini"
            round
            icon="far fa-trash-alt"
          ></el-button>
        </el-popconfirm>

        <el-button
          style="display: inline"
          class="table-action-button"
          size="mini"
          round
          icon="fas fa-plus"
          @click="$emit('add-scope-to-template', template)"
        ></el-button>
      </div>
    </div>

    <div
      v-show="templateSettingsVisible"
      style="margin-top: 20px;"
    >
      Тип прогрессивной шкалы
      <el-select
        style="margin-bottom:20px"
        v-model="template.calc_type"
        size="small"
        @change="$emit('update-template', template)"
      >
        <el-option label="Процентная" :value="1" ></el-option>
        <el-option label="Количественная" :value="2" ></el-option>
      </el-select>

      <div
        style="margin-bottom: 20px"
      >

        <el-input
          v-model="template.description"
          :disabled="!$canAndRulesPass(user, 'setting.scales_edit')"
          placeholder="Введите комментарий"
          type="textarea"
          :autosize="{ minRows: 2}"
          @change="$emit('update-template', template)"
        >
        </el-input>
      </div>


      <div>
        <el-row :gutter="40" style="margin-bottom: 5px">
          <el-col :span="6">Оценка выполнения цели, %</el-col>
          <el-col :span="6">Уровень достижения цели (факт)</el-col>
          <el-col :span="2"></el-col>
        </el-row>
      </div>
      <div
        v-for="scope in template.scopes"
      >
        <el-row :gutter="40" style="margin-bottom: 5px">
          <el-col :span="6">
            <el-input
              v-model="scope.pay"
              :disabled="!$canAndRulesPass(user, 'setting.scales_edit')"
              size="mini"
              type="number"
              @change="$emit('update-template', template)"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="scope.target"
              :disabled="!$canAndRulesPass(user, 'setting.scales_edit')"
              size="mini"
              type="number"
              @change="$emit('update-template', template)"
            ></el-input>
          </el-col>
          <el-col :span="2" align="right">
            <el-popconfirm
              v-if="$canAndRulesPass(user, 'setting.scales_edit')"
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              title="Вы уверены, что хотите удалить диапазон?"
              @confirm="$emit('delete-template-scope', {template, scope})"
            >
              <el-button
                slot="reference"
                class="table-action-button"
                size="mini"
                round
                icon="far fa-trash-alt"
              ></el-button>
            </el-popconfirm>
          </el-col>
        </el-row>

      </div>

    </div>
  </el-card>
</template>

<script>
import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import PageHeading from "@/components/PageHeading.vue";
import LibraryScalesUnitsModal from "@/components/library/LibraryScalesUnitsModal.vue";
import companyConfiguration from "../../../plugins/companyConfiguration";
import {mapGetters} from "vuex";

export default {
  name: "progressive-scale-setting-template",
  components: {LibraryScalesUnitsModal, PageHeading, ClickToEdit},
  props: {
    template: {type: Object, required: true},
  },
  computed:{
    ...mapGetters([ 'user' ]),
  },
  data() {
    return {
      templateSettingsVisible: false,
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>


<style lang="scss" scoped>
.scales-settings-visibility {
  padding: 5px 10px 5px 0;
  cursor: pointer;
}
.scales-settings-name .not-editable{
  margin-top:5px;
}
</style>